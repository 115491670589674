export default {
  SYSTEM: {
    translation: {
    },
  },
  PROJECT: {
    translation: {
    }
  },
  PROGRAM: {
    translation: {
    }
  },
  PORTFOLIO: {
    translation: {
    }
  },
}