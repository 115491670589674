interface IPpmRequest {
  data: any;
}

export const initialState = ({
  data: {},
} as IPpmRequest);

export const SET_PPM_REQUEST_DATA = 'SET_PPM_REQUEST_DATA';
export const RESET_PPM_REQUEST_DATA = 'RESET_PPM_REQUEST_DATA';

export const PpmRequest = (state = initialState, action): IPpmRequest => {
  switch (action.type) {
    case SET_PPM_REQUEST_DATA:
      return { ...state, data: action.payload };
    case RESET_PPM_REQUEST_DATA:
      return { ...state, data: {} };
    default:
      return state;
  }
};
